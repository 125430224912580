import React, {useState,useEffect} from 'react';
import { BrowserRouter ,Route, Routes } from 'react-router-dom';
import { AppContext } from './context/contextApi';
import Headers from "./components/Header";
import Feed from "./components/Feed";
import SearchResult from "./components/SearchResult";
import VideoDetails from "./components/VideoDetails";
const App = () => {
 
  return (
<AppContext>
<BrowserRouter>
<div className="flex flex-col h-full">
  <Headers />

      <Routes>
          <Route path="/" exact element={<Feed />} />
          <Route path="/searchResult/:searchQuery" element={<SearchResult />} />
          <Route path="/video/:id" element={<VideoDetails />} />
      </Routes>
</div>

</BrowserRouter>

</AppContext>
  


  )
}

export default App